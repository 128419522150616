<template>
  <sm-editable-item
    v-model="form"
    :controllerName="controllerName"
    :isLoading="item.isLoading"
    :breadcrumbs="breadcrumbs"
    :pageHeader="pageHeader"
    :fields="fields"
    :disabledSaveButton="isEqual"
    @save="onSave('MailBoxesRules')"
    @cancel="onCancel('MailBoxesRules')"
  />
</template>

<script>
// mixins
import editableListItemEdit from '@/mixins/editableListItemEdit.js';
// vuex
import { mapState, mapActions } from 'vuex';
// components
import SmEditableItem from '@/components/views/SmEditableItem.vue';

export default {
  name: 'MailBoxesRulesEdit',

  components: {
    SmEditableItem,
  },

  mixins: [editableListItemEdit],

  data() {
    return {
      controllerName: 'MailBoxesRules',
      pageHeader: 'Редактирование правил для почтовых ящиков',
      breadcrumbs: [
        {
          text: 'Администрирование',
          route: { name: 'AdministrationMain' },
        },
        {
          text: 'Правила для почтовых ящиков',
          route: { name: 'MailBoxesRules' },
        },
        {
          text: 'Редактирование правил для почтовых ящиков',
        },
      ],
      form: {},
    };
  },

  computed: {
    ...mapState({
      item: (state) => state.editableList.item,
      emails: (state) => state.common.emails.data,
      emailGroups: (state) => state.common.emailGroups.data,
    }),

    fields() {
      return [
        {
          form: [
            {
              type: 'text',
              key: 'name',
              label: 'Название правила',
            },
            {
              type: 'text',
              key: 'fromMail',
              label: 'Email адрес отправителя',
            },
            {
              type: 'select',
              key: 'mailBoxId',
              label: 'Почтовый ящик',
              list: this.emails
            },
            {
              type: 'select',
              key: 'mailBoxGroupId',
              label: 'Группа сообщений почтового ящика',
              list: this.emailGroups
            },
            {
              type: 'textarea',
              key: 'textInSubject',
              label: 'Текст в теме письма',
            },
          ],
        },
      ];
    },
  },

  created() {
    this.isLoadingPage = true;
    const emails = this.getCommonList({ name: 'Emails' });

    Promise.all([
      emails,
    ]).then(() => {
      this.getItem({ name: this.controllerName, id: this.id })
        .then(() => {
          this.form = this.lodash.cloneDeep(this.item.data);
        })
        .finally(() => {
          this.isLoadingPage = false;
        });
    });
  },

  watch: {
  'form.mailBoxId': {
    handler(newVal, oldVal) {
      if (newVal && newVal !== oldVal) {
        this.getCommonList({
          name: 'EmailGroups',
          params: { mailboxId: this.form.mailBoxId },
        });
      }
    },
    immediate: true,
  }},

  methods: {
    ...mapActions({
      getItem: 'editableList/getItem',
      updateItem: 'editableList/updateItem',
      getCommonList: 'common/getCommonList',
    }),
  },
};
</script>
